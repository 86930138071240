<div class="tb-dialog">
    <div class="tb-dialog__header">
        <h2 mat-dialog-title>
            <mat-icon>military_tech</mat-icon>
            Qualification Review
        </h2>

        <div class="tb-dialog__close" (click)="cancel()">
            <talentbridge-icon icon="dialog_close"></talentbridge-icon>
        </div>
    </div>

    <div class="tb-dialog__body">
        <mat-dialog-content>
            <form class="form grid-12" #qualificationReviewForm="ngForm">
                @if (qualification) {
                    <div class="g-col-6">
                        <esa-material-ff
                            name="Name"
                            [editMode]="false"
                            label="Name"
                            type="text"
                            [(ngModel)]="qualification.Name"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-6">
                        <esa-material-ff
                            name="QualificationType"
                            [editMode]="false"
                            label="Type"
                            type="dropdown"
                            [options]="[qualification.QualificationType]"
                            optionLabel="Name"
                            optionValue="QualificationTypeID"
                            [(ngModel)]="qualification.QualificationType.QualificationTypeID"
                        >
                        </esa-material-ff>
                    </div>
                      
                    <div class="g-col-6">
                        <esa-material-ff
                            name="ServiceLine"
                            [editMode]="false"
                            label="Service Line"
                            type="dropdown"
                            [options]="[qualification.Service.ServiceCategory.ServiceLine]"
                            optionLabel="Name"
                            optionValue="ServiceLineID"
                            [(ngModel)]="qualification.Service.ServiceCategory.ServiceLine.ServiceLineID"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-6">
                        <esa-material-ff
                            name="ServiceCategory"
                            [editMode]="false"
                            label="Service Category"
                            type="dropdown"
                            [options]="[qualification.Service.ServiceCategory]"
                            optionLabel="Name"
                            optionValue="ServiceCategoryID"
                            [(ngModel)]="qualification.Service.ServiceCategory.ServiceCategoryID"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-12">
                        <esa-material-ff
                            name="Service"
                            [editMode]="false"
                            label="Service"
                            type="dropdown"
                            [options]="[qualification.Service]"
                            optionLabel="Name"
                            optionValue="ServiceID"
                            [(ngModel)]="qualification.Service.ServiceID"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-12">
                        <esa-material-ff
                            name="Description"
                            [editMode]="false"
                            label="Description"
                            type="textarea"
                            [(ngModel)]="qualification.Description"
                        >
                        </esa-material-ff>
                    </div>

                    <div class="g-col-12">
                        <esa-material-ff
                            name="DecisionJustification"
                            [editMode]="true"
                            label="Decision Justification"
                            type="textarea"
                            [(ngModel)]="qualificationApprovalDto.DecisionJustification"
                        >
                        </esa-material-ff>
                    </div>
                }
            </form>
        </mat-dialog-content>
    </div>

    <div class="tb-dialog__footer">
        <div class="tb-dialog__actions-list">
            <mat-dialog-actions align="end">
                <esa-material-button
                    label="Approve"
                    type="primary"
                    [tooltip]="
                        !qualificationReviewForm.form.valid
                            ? 'You are missing required fields.'
                            : ''
                    "
                    (click)="approve(true)"
                    [disabled]="!qualificationReviewForm.form.valid"
                ></esa-material-button>
                <esa-material-button
                    label="Reject"
                    type="secondary"
                    [tooltip]="
                        !qualificationReviewForm.form.valid
                            ? 'You are missing required fields.'
                            : ''
                    "
                    (click)="approve(false)"
                    [disabled]="!qualificationReviewForm.form.valid"
                ></esa-material-button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
