
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { CustomPageDto } from "../../generated/model/custom-page-dto";
import { CustomPageService } from "../../generated/api/custom-page.service";
import { Subscription } from "rxjs";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { UserClaimsDto } from "../../generated/model/user-claims-dto";
import { environment } from "src/environments/environment";
import { TalentBridgeIconComponent } from "../talentbridge-icon/talentbridge-icon.component";

@Component({
  selector: 'talentbridge-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TalentBridgeIconComponent
]
})
export class SideNavComponent {
  @Input() currentUser: UserClaimsDto;
  @Input() userBeingImpersonated: boolean;
  @Input() userCanManageDashboard: boolean;
  @Input() userCanViewProjects: boolean;
  @Input() userCanViewClients: boolean;
  @Input() userCanViewEmployees: boolean;
  @Input() userCanViewQualifications: boolean;

  @Output() loginClicked = new EventEmitter<any>();
  @Output() logOutClicked = new EventEmitter<any>();

  customPagesSubscription: Subscription;

  windowWidth: number;
  viewPages: CustomPageDto[] = [];
  learnMorePages: CustomPageDto[] = [];
  mainAppApiUrl: string;

  constructor(private customPageService: CustomPageService) { }

  ngOnInit() {
    this.mainAppApiUrl = environment.mainAppApiUrl;
    if (this.currentUser) {
      this.customPagesSubscription = this.customPageService.customPagesWithRolesGet().subscribe(customPagesWithRoles => {
        this.viewPages = customPagesWithRoles.filter(x => x.MenuItem.Name == "View");
        this.learnMorePages = customPagesWithRoles.filter(x => x.MenuItem.Name == "LearnMore");
      });
    }
  }

  ngOnDestroy() {
    this.customPagesSubscription?.unsubscribe();
  }

  login(): void {
    this.loginClicked.emit();
  }

  logout(): void {
    this.logOutClicked.emit();
  }

}
