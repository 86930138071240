/**
 * TalentBridge.API, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class ProjectUpsertDto { 
    Name: string;
    ClientID: number;
    DNumber: string;
    Status: string;
    ProjectManager: string;
    StartDate?: string;
    EndDate?: string;
    Address1?: string;
    Address2?: string;
    Address3?: string;
    City?: string;
    State?: string;
    ZipCode?: string;
    County?: string;
    Country?: string;
    Description?: string;
    StandardDescription?: string;
    OpportunityDescription?: string;
    Longitude?: number;
    Latitude?: number;
    ProjectType?: string;
    ServiceType?: string;
    Market?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
