<form class="form grid-12" #employeeForm="ngForm">
    <div class="contact-info g-col-8 grid-8">
        <div class="section-header | g-col-8 bottom-border">
            <h2 class="section-title uppercase-small">Contact Info</h2>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="FirstName"
                [editMode]="false"
                label="First Name"
                type="text"
                [(ngModel)]="employee.FirstName"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="LastName"
                [editMode]="false"
                label="Last Name"
                type="text"
                [(ngModel)]="employee.LastName"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="Email"
                [editMode]="false"
                label="Email"
                type="text"
                [(ngModel)]="employee.Email"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="MobilePhone"
                [editMode]="false"
                label="Mobile Phone"
                type="text"
                [(ngModel)]="employee.MobilePhone"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="BusinessPhone"
                [editMode]="false"
                label="Business Phone"
                type="text"
                [(ngModel)]="employee.BusinessPhone"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="ZipCode"
                [editMode]="editMode"
                [required]="false"
                label="Zip Code"
                type="text"
                [(ngModel)]="employeeUpsertDto.ZipCode"
                [maxlength]="30"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="PreferredPronouns"
                [editMode]="editMode"
                [required]="false"
                label="Preferred Pronouns"
                type="text"
                [(ngModel)]="employeeUpsertDto.PreferredPronouns"
                [maxlength]="30"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="AvailableForWork"
                [editMode]="editMode"
                [required]="false"
                label="Available for work"
                type="checkbox"
                [(ngModel)]="employeeUpsertDto.AvailableForWork">
            </esa-material-ff>
        </div>
    </div>

    <div class="professional-info g-col-8 grid-8">
        <div class="section-header | g-col-8 bottom-border">
            <h2 class="section-title uppercase-small">Professional Info</h2>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="PathwayTitle"
                [editMode]="false"
                label="Pathway Title"
                type="text"
                [(ngModel)]="employee.PathwayTitle"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="Organization"
                [editMode]="false"
                label="Organization"
                type="text"
                [(ngModel)]="employee.Organization"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-4">
            <div class="kv-pair">
                <esa-label label="Manager"></esa-label>
                <div class="value-display ff-display">
                    @if (employee.Manager) {
                        <a
                            [routerLink]="[
                                '/employees',
                                employee.Manager.UserID
                            ]"
                            >{{ employee.Manager.FullName }}</a
                        >
                    } @else {
                        -
                    }
                </div>
            </div>
        </div>

        <div class="g-col-4">
            <esa-material-ff
                name="OfficeLocation"
                [editMode]="false"
                label="Office Location"
                type="text"
                [(ngModel)]="employee.OfficeLocation"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-8">
            <esa-material-ff
                name="Bio"
                [editMode]="editMode"
                [required]="false"
                label="Bio"
                type="textarea"
                [(ngModel)]="employeeUpsertDto.Bio"
            >
            </esa-material-ff>
        </div>
    </div>

    @if (editMode) {
        <div class="g-col-12 actions-bar actions-bar__footer">
            <esa-material-button
                style="margin-right: 1rem"
                type="primary"
                label="Save"
                icon="save"
                [tooltip]="
                    !employeeForm.form.valid
                        ? getTooltip()
                        : ''
                "
                (click)="saveForm(form)"
                [disabled]="!employeeForm.form.valid"
            >
            </esa-material-button>
            <esa-material-button
                type="clear"
                label="Cancel"
                icon="cancel"
                (click)="cancelEditMode()"
            >
            </esa-material-button>
        </div>
    }
</form>
