<span [ngSwitch]="icon">
    
    <ng-container *ngSwitchCase="'dialog_close'">
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.7342 0.274897C11.65 0.190519 11.55 0.123576 11.4399 0.0779014C11.3298 0.0322267 11.2117 0.00871629 11.0925 0.00871629C10.9733 0.00871629 10.8553 0.0322267 10.7452 0.0779014C10.6351 0.123576 10.535 0.190519 10.4508 0.274897L6 4.71663L1.54916 0.265794C1.4649 0.181527 1.36486 0.114683 1.25476 0.0690775C1.14466 0.0234724 1.02665 8.879e-10 0.90748 0C0.788308 -8.879e-10 0.670302 0.0234724 0.560202 0.0690775C0.450101 0.114683 0.350062 0.181527 0.265794 0.265794C0.181527 0.350062 0.114683 0.450101 0.0690775 0.560202C0.0234724 0.670302 -8.879e-10 0.788308 0 0.90748C8.879e-10 1.02665 0.0234724 1.14466 0.0690775 1.25476C0.114683 1.36486 0.181527 1.4649 0.265794 1.54916L4.71663 6L0.265794 10.4508C0.181527 10.5351 0.114683 10.6351 0.0690775 10.7452C0.0234724 10.8553 0 10.9733 0 11.0925C0 11.2117 0.0234724 11.3297 0.0690775 11.4398C0.114683 11.5499 0.181527 11.6499 0.265794 11.7342C0.350062 11.8185 0.450101 11.8853 0.560202 11.9309C0.670302 11.9765 0.788308 12 0.90748 12C1.02665 12 1.14466 11.9765 1.25476 11.9309C1.36486 11.8853 1.4649 11.8185 1.54916 11.7342L6 7.28337L10.4508 11.7342C10.5351 11.8185 10.6351 11.8853 10.7452 11.9309C10.8553 11.9765 10.9733 12 11.0925 12C11.2117 12 11.3297 11.9765 11.4398 11.9309C11.5499 11.8853 11.6499 11.8185 11.7342 11.7342C11.8185 11.6499 11.8853 11.5499 11.9309 11.4398C11.9765 11.3297 12 11.2117 12 11.0925C12 10.9733 11.9765 10.8553 11.9309 10.7452C11.8853 10.6351 11.8185 10.5351 11.7342 10.4508L7.28337 6L11.7342 1.54916C12.0801 1.20329 12.0801 0.620769 11.7342 0.274897Z"
                fill="#7C7C7C"
            />
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'skill'">
        <svg width="16" height="16" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Skill</title>
            <path d="M26.6355 26.15H26.65M26.6355 26.15C25.7336 27.0447 24.098 26.8228 22.9496 26.8228C21.5402 26.8228 20.8616 27.0983 19.8567 28.1032C19.0012 28.9587 17.8543 30.5 16.5 30.5C15.1457 30.5 13.9988 28.9587 13.1433 28.1032C12.137 27.0983 11.4584 26.8228 10.0504 26.8228C8.90345 26.8228 7.2664 27.0461 6.36305 26.15C5.4539 25.2481 5.6772 23.6038 5.6772 22.4496C5.6772 20.9895 5.3582 20.3181 4.31855 19.2799C2.77285 17.7342 2 16.9599 2 16C2 15.0401 2.77285 14.2658 4.32 12.7201C5.248 11.7921 5.6772 10.8728 5.6772 9.5504C5.6772 8.40345 5.4539 6.7664 6.35 5.86305C7.2519 4.9539 8.8962 5.1772 10.0504 5.1772C11.3728 5.1772 12.2921 4.74655 13.2201 3.81855C14.7658 2.27285 15.5401 1.5 16.5 1.5C17.4599 1.5 18.2342 2.27285 19.7799 3.82C20.7079 4.748 21.6272 5.1772 22.9496 5.1772C24.0966 5.1772 25.7336 4.9539 26.6369 5.85C27.5461 6.7519 27.3228 8.3962 27.3228 9.5504C27.3228 11.0106 27.6418 11.6819 28.6814 12.7201C30.2271 14.2658 31 15.0401 31 16C31 16.9599 30.2272 17.7342 28.68 19.2799C27.6418 20.3196 27.3228 20.9909 27.3228 22.4496C27.3228 23.6038 27.5447 25.2481 26.6355 26.15Z" stroke="#3F5D5F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.15 17.2948L14.76 19.625L20.85 12.375" stroke="#3F5D5F" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'license'">
        <svg width="16" height="16" viewBox="0 0 39 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>License</title>
            <path d="M35.0556 0.5H3.94445C2.2489 0.562222 0.895566 1.91556 0.833344 3.61111V25.3889C0.895566 27.0844 2.2489 28.4378 3.94445 28.5H35.0556C36.7511 28.4378 38.1045 27.0844 38.1667 25.3889V3.61111C38.138 2.79511 37.8011 2.02031 37.2237 1.44295C36.6464 0.865598 35.8716 0.528631 35.0556 0.5ZM35.0556 25.3889H3.94445V3.61111H35.0556V25.3889ZM22.6111 22.2778V20.3333C22.6111 17.7511 17.4156 16.4444 14.8333 16.4444C12.2511 16.4444 7.05556 17.7511 7.05556 20.3333V22.2778H22.6111ZM14.8333 6.72222C13.8019 6.72222 12.8128 7.13194 12.0835 7.86125C11.3542 8.59056 10.9445 9.57971 10.9445 10.6111C10.9445 11.1218 11.045 11.6275 11.2405 12.0993C11.4359 12.5711 11.7224 12.9999 12.0835 13.361C12.8128 14.0903 13.8019 14.5 14.8333 14.5C15.344 14.5 15.8497 14.3994 16.3216 14.204C16.7934 14.0085 17.2221 13.7221 17.5832 13.361C17.9443 12.9999 18.2308 12.5711 18.4262 12.0993C18.6216 11.6275 18.7222 11.1218 18.7222 10.6111C18.7222 10.1004 18.6216 9.59472 18.4262 9.1229C18.2308 8.65108 17.9443 8.22237 17.5832 7.86125C17.2221 7.50013 16.7934 7.21368 16.3216 7.01825C15.8497 6.82281 15.344 6.72222 14.8333 6.72222ZM22.6111 6.72222V8.27778H31.9445V6.72222H22.6111ZM22.6111 9.83333V11.3889H31.9445V9.83333H22.6111ZM22.6111 12.9444V14.5H28.8333V12.9444H22.6111Z" fill="#3F5D5F"/>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'certificate'">
        <svg width="16" height="16" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Certification</title>
            <path d="M0.388916 0.5H34.6111V28.5H0.388916V0.5ZM3.50003 3.61111V25.3889H31.5V3.61111H28.3889V13.7222L23.7222 10.2222L19.0556 13.7222V3.61111H3.50003ZM22.1667 3.61111V7.5L23.7222 6.33333L25.2778 7.5V3.61111H22.1667ZM6.61114 12.9444H15.9445V16.0556H6.61114V12.9444ZM6.61114 19.1667H28.3889V22.2778H6.61114V19.1667Z" fill="#3F5D5F"/>
        </svg>
    </ng-container>
    
    <ng-container *ngSwitchCase="'esa_logo_home'">
        <svg
            width="81"
            height="49"
            viewBox="0 0 81 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.4039 0.955719H0V22.3521C1.10379 11.021 10.0724 2.05556 21.4039 0.955719Z"
                fill="white"
            />
            <path
                d="M26.0003 48.3562H47.4023V26.956C46.3004 38.2879 37.3323 47.2552 26.0003 48.3562Z"
                fill="white"
            />
            <path
                d="M30.6864 20.4844H32.9911V1.46747H30.6864V0.95572H47.4023V6.62047H46.8794V6.22451C46.8794 3.63214 44.9724 1.46747 41.5844 1.46747H37.4344V10.1822H39.4086C41.2576 10.1822 42.2269 9.12879 42.2269 6.42249V6.10311H42.7387V15.1073H42.2269V14.4518C42.2269 12.429 41.2576 10.6939 39.4086 10.6939H37.4344V20.4844H41.5433C46.2126 20.4844 46.8943 16.6108 46.8943 14.9859V14.5937H47.4098V21.0037H30.6864V20.4844ZM49.4213 13.4563H49.9331C50.6166 16.6743 52.7794 20.9439 56.6232 20.9439C59.0736 20.9439 60.7228 19.4367 60.7228 16.9302C60.7228 10.7219 49.3634 15.1951 49.3634 6.62047C49.3634 2.86078 51.868 0.5 55.6258 0.5C58.504 0.5 59.4154 2.03525 60.7004 2.03525C61.5558 2.03525 61.6137 1.28817 61.6137 0.927704H62.1273V7.33393H61.6137C60.9021 4.31385 59.3071 1.01175 55.7173 1.01175C53.5508 1.01175 51.9222 2.46483 51.9222 4.65751C51.9222 10.1542 63.4571 5.82483 63.4571 14.2818C63.4571 18.7232 60.9507 21.4575 56.9351 21.4575C54.4585 21.4575 52.1239 19.9783 50.9846 19.9783C50.2151 19.9783 49.9312 20.49 49.9312 21.0317H49.4194L49.4213 13.4563ZM62.3178 20.4844H62.6914C63.7728 20.4004 64.2882 19.7149 64.63 18.7736L71.2305 0.5H71.6601L79.2915 20.4844H80.6848V20.9981H72.1998V20.4844H74.4747L72.3997 14.5657H66.875L65.5079 18.5812C65.3914 18.8524 65.3237 19.142 65.308 19.4367C65.308 20.3705 66.1055 20.49 66.677 20.49H67.4727V21.0037H62.3178V20.4844ZM72.17 14.0483L69.6934 6.85019H69.6373L67.1029 14.0539L72.17 14.0483Z"
                fill="white"
            />
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'talentbridge_logo'">
        <svg
            viewBox="0 0 180 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.8396 120.817H12.172C14.0287 120.817 15.2665 121.229 15.8854 121.951C16.5044 122.673 16.917 123.602 16.917 124.633C16.917 125.665 16.8138 125.768 16.5044 126.284C16.2981 126.696 15.8854 127.212 15.3697 127.625C16.4012 127.934 17.1233 128.553 17.5359 129.172C17.9485 129.894 18.1548 130.616 18.1548 131.441C18.1548 132.782 17.639 133.917 16.7107 134.639C15.7823 135.361 14.4413 135.774 12.7909 135.774H7.8396V120.92V120.817ZM11.1405 123.292V126.593H11.8625C12.3783 126.593 12.894 126.387 13.2035 126.077C13.6161 125.768 13.7193 125.355 13.7193 124.84C13.7193 124.324 13.513 124.117 13.2035 123.808C12.7909 123.499 12.3783 123.395 11.8625 123.395H11.1405V123.292ZM11.1405 129.069V133.195H12.2751C14.0287 133.195 14.9571 132.576 14.9571 131.235C14.9571 129.894 14.7508 129.997 14.3382 129.585C13.9256 129.172 13.3066 129.069 12.4814 129.069H11.1405Z"
                fill="#454040"
            />
            <path
                d="M38.7852 120.817H43.7364C45.1806 120.817 46.4184 121.229 47.2436 121.951C48.0688 122.673 48.5846 123.808 48.5846 125.149C48.5846 126.49 48.3783 126.696 47.9657 127.521C47.5531 128.347 46.831 128.966 45.7995 129.481L49.9256 135.67H46.2121L42.3955 129.894H41.9829V135.67H38.7852V120.817ZM41.9829 123.395V127.315H42.7049C44.3554 127.315 45.1806 126.593 45.1806 125.252C45.1806 123.911 44.9743 124.221 44.5617 123.911C44.1491 123.602 43.5301 123.395 42.7049 123.395H41.9829Z"
                fill="#454040"
            />
            <path
                d="M70.8654 120.817H74.0631V135.67H70.8654V120.817Z"
                fill="#454040"
            />
            <path
                d="M95.5187 120.817H100.573C102.739 120.817 104.493 121.539 105.834 122.88C107.175 124.221 107.794 126.077 107.794 128.244C107.794 130.41 107.072 132.266 105.731 133.607C104.39 134.948 102.533 135.67 100.264 135.67H95.4155V120.817H95.5187ZM98.8195 123.602V132.782H100.057C101.398 132.782 102.43 132.473 103.255 131.751C104.08 131.029 104.493 129.791 104.493 128.14C104.493 126.49 104.287 126.284 103.874 125.562C103.461 124.84 102.842 124.324 102.12 124.014C101.295 123.705 100.573 123.602 99.8511 123.602H98.7164H98.8195Z"
                fill="#454040"
            />
            <path
                d="M141.628 124.014L139.461 125.974C139.049 125.252 138.43 124.633 137.811 124.221C137.192 123.808 136.47 123.602 135.748 123.602C134.613 123.602 133.582 124.014 132.756 124.943C131.931 125.871 131.519 126.903 131.519 128.037C131.519 129.172 131.931 130.41 132.756 131.338C133.582 132.266 134.613 132.679 135.851 132.679C137.089 132.679 137.192 132.473 137.708 132.163C138.327 131.854 138.842 131.235 139.152 130.307H135.645V127.418H142.556V129.378C142.556 130.41 142.246 131.441 141.524 132.576C140.802 133.711 139.977 134.536 138.842 135.052C137.708 135.567 136.573 135.877 135.438 135.877C133.375 135.877 131.622 135.155 130.178 133.607C128.734 132.06 128.011 130.307 128.011 128.037C128.011 125.768 128.734 124.221 130.178 122.673C131.622 121.229 133.375 120.507 135.438 120.507C137.501 120.507 140.08 121.642 141.524 123.911L141.628 124.014Z"
                fill="#454040"
            />
            <path
                d="M163.599 120.817H171.954V123.808H166.797V126.799H171.954V129.688H166.797V132.782H171.954V135.774H163.599V120.92V120.817Z"
                fill="#454040"
            />
            <path
                d="M2.99146 79.659H21.043V85.3324H14.8539V106.479H9.07741V85.3324H2.99146V79.659Z"
                fill="#454040"
            />
            <path
                d="M39.9198 79.659H44.4585L55.3926 106.582H49.5129L47.3467 101.321H37.0316L34.9685 106.582H29.192L40.023 79.659H39.9198ZM42.086 88.4269L38.8883 96.3696H45.2837L42.086 88.4269Z"
                fill="#454040"
            />
            <path
                d="M68.0802 79.659H73.8567V100.805H81.7994V106.582H68.0802V79.659Z"
                fill="#454040"
            />
            <path
                d="M94.384 79.659H109.547V85.0229H100.16V90.49H109.547V95.7507H100.16V101.321H109.547V106.685H94.384V79.7622V79.659Z"
                fill="#454040"
            />
            <path
                d="M140.802 79.659H146.476V106.582H140.802L128.527 89.149V106.582H122.751V79.659H128.527L140.905 96.9885V79.659H140.802Z"
                fill="#454040"
            />
            <path
                d="M158.544 79.659H176.596V85.3324H170.407V106.479H164.63V85.3324H158.544V79.659Z"
                fill="#454040"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M162.155 29.4241C163.186 30.4556 164.115 31.4871 165.043 32.4155C128.94 -0.696272 72.8252 0.23209 37.9599 35.2006C30.8424 42.3181 25.1691 50.3639 20.8367 58.8224H0C4.84814 48.0946 11.6562 38.0888 20.4241 29.3209C59.5186 -9.77364 123.06 -9.77364 162.155 29.3209V29.4241Z"
                fill="#F89F33"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M165.559 39.7392C171.438 45.6189 176.183 52.0143 180 58.9255H154.625C151.84 54.5931 148.539 50.467 144.722 46.6504C116.252 18.1805 70.7622 17.149 41.0544 43.3496C42.1891 42.1117 43.3238 40.8739 44.5616 39.6361C77.9828 6.21489 132.241 6.21489 165.662 39.6361L165.559 39.7392Z"
                fill="#F48256"
            />
        </svg>
    </ng-container>

</span>