import { Component, Input } from '@angular/core';
import { TalentBridgeIconComponent } from '../talentbridge-icon/talentbridge-icon.component';

@Component({
  selector: 'qualification-chips-icon',
  templateUrl: './qualification-chips-icon.component.html',
  styleUrl: './qualification-chips-icon.component.css',
  standalone: true,
  imports: [
    TalentBridgeIconComponent
  ]
})
export class QualificationChipsIconComponent {

    @Input() qualificationTypeID: number;
}
