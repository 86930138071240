<div class="tb-dialog">
    <div class="tb-dialog__header">
        <h2 mat-dialog-title>
            <mat-icon>military_tech</mat-icon>
            Suggest a Skill or Qualification
        </h2>

        <div class="tb-dialog__close" (click)="closeDialog(true)">
            <talentbridge-icon icon="dialog_close"></talentbridge-icon>
        </div>
    </div>

    <div class="tb-dialog__body">
        <mat-dialog-content>
            <form class="form grid-12" #qualificationSuggestForm="ngForm">
                @if (qualificationUpsertDto) {
                    <div class="g-col-6">
                        <esa-material-ff
                            name="Name"
                            [editMode]="true"
                            [required]="true"
                            label="Name"
                            type="text"
                            [(ngModel)]="qualificationUpsertDto.Name"
                            [maxlength]="200"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-6">
                        <esa-material-ff
                            name="QualificationType"
                            [editMode]="true"
                            [required]="true"
                            label="Type"
                            type="dropdown"
                            [options]="qualificationTypes"
                            optionLabel="Name"
                            optionValue="QualificationTypeID"
                            [(ngModel)]="qualificationUpsertDto.QualificationTypeID"
                        >
                        </esa-material-ff>
                    </div>
                      
                    <div class="g-col-6">
                        <esa-material-ff
                            name="ServiceLine"
                            [editMode]="true"
                            [required]="true"
                            label="Service Line"
                            type="dropdown"
                            [options]="serviceLines"
                            optionLabel="Name"
                            optionValue="ServiceLineID"
                            [(ngModel)]="selectedServiceLineID"
                            (ngModelChange)="filterServiceLine()"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-6">
                        <esa-material-ff
                            name="ServiceCategory"
                            [editMode]="true"
                            [required]="true"
                            label="Service Category"
                            type="dropdown"
                            [options]="serviceCategoriesFiltered"
                            optionLabel="Name"
                            optionValue="ServiceCategoryID"
                            [(ngModel)]="selectedServiceCategoryID"
                            (ngModelChange)="filterServiceCategory()"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-12">
                        <esa-material-ff
                            name="Service"
                            [editMode]="true"
                            [required]="true"
                            label="Service"
                            type="dropdown"
                            [options]="servicesFiltered"
                            optionLabel="Name"
                            optionValue="ServiceID"
                            [(ngModel)]="qualificationUpsertDto.ServiceID"
                        >
                        </esa-material-ff>
                    </div>
            
                    <div class="g-col-12">
                        <esa-material-ff
                            name="Description"
                            [editMode]="true"
                            label="Description"
                            type="textarea"
                            [(ngModel)]="qualificationUpsertDto.Description"
                        >
                        </esa-material-ff>
                    </div>
                }
            </form>
        </mat-dialog-content>
    </div>

    <div class="tb-dialog__footer">
        <div class="tb-dialog__actions-list">
            <mat-dialog-actions align="end">
                <esa-material-button
                    label="Submit"
                    type="secondary"
                    [tooltip]="
                        !qualificationSuggestForm.form.valid
                            ? getTooltip()
                            : ''
                    "
                    (click)="closeDialog(false)"
                    [disabled]="!qualificationSuggestForm.form.valid"
                ></esa-material-button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
