import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "talentbridge-icon",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./talentbridge-icon.component.html",
    styleUrls: ["./talentbridge-icon.component.scss"]
})

export class TalentBridgeIconComponent {
    @Input() icon: typeof TalentBridgeIconInterface;
}

export var TalentBridgeIconInterface:
    | "dialog_close"
    | "skill"
    | "license"
    | "certificate"
    | "esa_logo_home"
    | "talentbridge_logo";