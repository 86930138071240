import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from "@angular/core";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Router } from "@angular/router";
import { forkJoin, Subscription } from "rxjs";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { TalentBridgeGridComponent } from "src/app/shared/components/talentbridge-grid/talentbridge-grid.component";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { MatIcon } from "@angular/material/icon";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { EmployeeQualificationService } from "src/app/shared/generated/api/employee-qualification.service";
import { MatDialog } from "@angular/material/dialog";
import { QualificationAddDialog } from "../shared/qualification-add-dialog/qualification-add-dialog.component";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { Alert } from "src/app/shared/models/alert";
import { AlertService } from "src/app/shared/services/alert.service";
import { QualificationSuggestDialog } from '../shared/qualification-suggest-dialog/qualification-suggest-dialog.component';
import { QualificationStatusEnum } from "src/app/shared/generated/enum/qualification-status-enum";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";

@Component({
    selector: "talentbridge-qualification-list",
    templateUrl: "./qualification-list.component.html",
    styleUrls: ["./qualification-list.component.scss"],
    standalone: true,
    imports: [
        CustomRichTextComponent,
        EsaMaterialButtonComponent,
        TalentBridgeGridComponent,
        MatIcon
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationListComponent implements OnInit, OnDestroy {

    public currentUser: UserDto;

    canEditText: boolean;  
    public richTextTypeID : number = CustomRichTextTypeEnum.QualificationIndex;
    public canCreate: boolean;

    public rowData = [];
    columnDefs: ColDef[];

    userSubscription: Subscription;
    qualificationSubscriptions: Subscription;
    userAddSubscription: Subscription;

    gridApi: any;
    constructor(
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private qualificationService: QualificationService,
        private employeeQualificationService: EmployeeQualificationService,
        private router: Router,
        public dialog: MatDialog,
        private dateColumnCreator: DateColumnCreatorService,
        private alertService: AlertService,
    ) {
    }

    ngOnInit() {
        this.userSubscription = this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;

                this.canEditText = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);

                this.canCreate = this.authenticationService.hasPermission(
                    this.currentUser,
                    PermissionEnum.QualificationRights,
                    RightsEnum.Create
                );

                this.cdr.markForCheck();
                this.createColumnDefs();
            });
    }

    createColumnDefs() {
        let component = this;
        this.columnDefs = [
            {
                headerName: "Service Line",
                field: "Service.ServiceCategory.ServiceLine.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.ServiceLine.Name",
                },
                tooltipField: "Service.ServiceCategory.ServiceLine.Name",
            },
            {
                headerName: "Service Category",
                field: "Service.ServiceCategory.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.ServiceCategory.Name",
                },
                tooltipField: "Service.ServiceCategory.Name",
            },
            {
                headerName: "Service",
                field: "Service.Name",
                flex: 3,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Service.Name",
                },
                tooltipField: "Service.Name",
            },
            {
                headerName: "",
                cellRenderer: ButtonRendererComponent, 
                cellRendererParams: function (params: any) {

                    return {
                        onClick: component.onClickAddQualification.bind(component),
                        icon: params.data["$UserHasQualification"] ? "check_circle_outlined" : "add_circle_outlined",
                    };
                },
                valueGetter: function (params: any) {
                    return params.data["$UserHasQualification"];
                },
                width: 50,
                colId: '$UserHasQualification',
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.QualificationID,
                        LinkDisplay: params.data.Name,
                    };
                },
                cellRendererSelector: (params) => {
                    return {
                            component: LinkRendererComponent,
                            params: { inRouterLink: "./" },
                        };
                },
                filterValueGetter: function (params: any) {
                    return params.data.Name;
                },
                comparator: function (linkA: any, linkB: any) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                flex: 3,
                tooltipField: "$NameAndDescription",
                wrapText: true,
            },
            {
                headerName: "Employee Count",
                valueGetter: function (params: any) {
                    return params.data.EmployeeCount;
                },
                filter: "agNumberColumnFilter",
                flex: 2,
                tooltipField: "EmployeeCount",
                cellDataType: "number" 
            },
            {
                headerName: "Type",
                field: "QualificationType.Name",
                flex: 2,
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "QualificationType.Name",
                },
                tooltipField: "QualificationType.Name",
            },
            this.dateColumnCreator.createDateColumnDef(
                "Date Created",
                "$CreateDate",
                "M/dd/YYYY"
            ),
            this.dateColumnCreator.createDateColumnDef(
                "Date Modified",
                "$UpdateDate",
                "M/dd/YYYY"
            ),
        ];
    }

    onQualificationsGridReady(gridEvent) {
        this.gridApi = gridEvent.api;
        this.gridApi.showLoadingOverlay();
        this.qualificationSubscriptions = forkJoin([
            this.qualificationService.qualificationsGet(),
            this.employeeQualificationService.employeesUserIDQualificationsGet(this.currentUser.UserID)
        ]).subscribe(([qualifications, employeeQualifications]) => {
            this.rowData = qualifications.filter((q) => {
                return q.QualificationStatus.QualificationStatusID === QualificationStatusEnum.Approved;
            }).map((qual) => {
                qual["$NameAndDescription"] = qual.Description ? `${qual.Name}: ${qual.Description}` : qual.Name;
                var userQual = employeeQualifications.find((eq) => eq.Qualification.QualificationID === qual.QualificationID);
                qual["$UserHasQualification"] = userQual != undefined;
                qual["$UserQualification"] = userQual;
                qual["$CreateDate"] = new Date(qual.CreateDate);
                qual["$UpdateDate"] = qual.UpdateDate ? new Date(qual.UpdateDate) : null;
                return qual;
            });
            this.gridApi.hideOverlay();
            this.cdr.markForCheck();
        });        
    }

    onClickAddQualification(p) {
        const dialogRef = this.dialog.open(QualificationAddDialog, {
            data: {
                Qualification: p.rowData,
                ExperienceLevel: p.rowData["$UserQualification"]?.ExperienceLevel,
            },
        });

        return dialogRef.afterClosed().subscribe((dto) => {
            if (dto) {
                var successFunc =  (param) => {
                    this.alertService.pushAlert(
                        new Alert(
                            `Qualification was successfully added to your profile.`,
                            AlertContext.Success,
                            true,
                        ),
                    );

                    p.rowData["$UserQualification"] = param;
                    p.rowData["$UserHasQualification"] = true; 
                    this.cdr.markForCheck();
                    this.gridApi.refreshCells({
                        force: true,
                        columns: ['$UserHasQualification'], // colId of the column you want to refresh
                      });
                };
                var errorFunc = (error) => {
                    this.alertService.pushAlert(
                        new Alert(
                            `There was an error adding the qualification to your profile. Please try again.`,
                            AlertContext.Danger,
                            true,
                        ),
                    );
                };
                if (!p.rowData["$UserQualification"]) {
                    this.userAddSubscription = this.employeeQualificationService.employeesUserIDQualificationsPost(this.currentUser.UserID, dto)
                    .subscribe(
                        successFunc,
                        errorFunc
                    );
                } else {
                    this.userAddSubscription = this.employeeQualificationService.employeesUserIDQualificationsQualificationIDPut(this.currentUser.UserID, dto.QualificationID, dto) 
                    .subscribe(                        
                        successFunc,
                        errorFunc
                    );
                }
            }
        });
    }

    navigateToQualificationCreatePage() {
        this.router.navigateByUrl("qualifications/create");
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.qualificationSubscriptions?.unsubscribe();
        this.userAddSubscription?.unsubscribe();
    }

    openQualificationSuggestDialog() {
        const dialogRef = this.dialog.open(QualificationSuggestDialog, {
            data: { }
        });

        return dialogRef.afterClosed().subscribe((dto) => {
            if (dto) {
                this.qualificationService.qualificationsSuggestPost(dto).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The suggested qualification was successfully submitted.", AlertContext.Success), 5000);
                },
                (error) => {
                    const isDuplicateName = error?.error?.hasOwnProperty("Duplicate Name");
                    let errorMessage = isDuplicateName ? "This qualification has already been suggested. Thank you for your interest." : "There was an error submitting the suggested qualification. Please try again."
                    this.alertService.pushAlert(new Alert(errorMessage, AlertContext.Danger, true));
                });
            }
        });
    }
}
