<div style="padding-right: 4px; display: inline-block;">
    <!-- Skill -->
    @if (qualificationTypeID == 1) {
        <talentbridge-icon icon="skill"></talentbridge-icon>
    }
    <!-- License -->
    @if (qualificationTypeID == 2) {
        <talentbridge-icon icon="license"></talentbridge-icon>
    }
    <!-- Certificate -->
    @if (qualificationTypeID == 3) {
        <talentbridge-icon icon="certificate"></talentbridge-icon>
    }
</div>
