<div class="tb-dialog">
    <div class="tb-dialog__header">
        <h2 mat-dialog-title>
            <h2 mat-dialog-title><mat-icon>public</mat-icon>Location</h2>
        </h2>

        <div class="tb-dialog__close" (click)="closeDialog(true)">
            <talentbridge-icon icon="dialog_close"></talentbridge-icon>
        </div>
    </div>

    <div class="tb-dialog__body">
        <mat-dialog-content>
            <p class="tb-dialog__label">Enter a Location</p>
            <mat-form-field>
                <mat-label>City, Postal Code, Lat/Long, etc...</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input
                    matInput
                    type="text"
                    name="locationSearchText"
                    placeholder="City, Postal Code, Lat/Long, etc..."
                    [formControl]="locationSearchControl"
                    [matAutocomplete]="auto"
                />

                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="selected($event)"
                    [displayWith]="getName.bind(this)"
                >
                    @for (loc of searchedLocations; track loc) {
                        <mat-option [value]="loc">{{
                            loc.display_name
                        }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>

            @if (location) {
                <mat-chip-set [attr.aria-label]="location.name">
                    <mat-chip (removed)="clear()">
                        {{ location.display_name }}
                        <button matChipRemove aria-label="Remove">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-set>
            }

            <mat-form-field>
                <mat-label>Distance</mat-label>
                <mat-select [(value)]="locationDistance">
                    @for (dist of distanceOptions; track $index) {
                        <mat-option [value]="dist.value">
                            {{ dist.display }}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </mat-dialog-content>
    </div>

    <div class="tb-dialog__footer">
        <mat-dialog-actions>
            <div class="tb-dialog__reset-link">
                <a href="javascript:void(0)" (click)="clear()">Reset Filters</a>
            </div>

            <div class="tb-dialog__actions-list">
                <esa-material-button
                    label="Apply"
                    icon="done"
                    type="secondary"
                    [disabled]="!location"
                    (click)="closeDialog(false)"
                ></esa-material-button>
            </div>
        </mat-dialog-actions>
    </div>
</div>
