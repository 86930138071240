<div class="tb-dialog">
    <div class="tb-dialog__header">
        <h2 mat-dialog-title>
            <mat-icon>military_tech</mat-icon>Skills & Qualifications
        </h2>

        <div class="tb-dialog__close" (click)="closeDialog(true)">
            <talentbridge-icon icon="dialog_close"></talentbridge-icon>
        </div>
    </div>

    <div class="tb-dialog__body">
        <mat-dialog-content>
            <p class="tb-dialog__label">Add a Skill or Qualification</p>
            @if (qualifications && experienceLevels) {
                <talentbridge-qualification-autocomplete
                    #QualificationAutocomplete
                    [editMode]="true"
                    [qualifications]="qualifications"
                    [experienceLevels]="experienceLevels"
                    [employeeQualificationDtos]="inputQualificationDtos"
                    (qualificationAdded)="qualificationAdded($event)"
                    (qualificationRemoved)="qualificationRemoved($event)"
                ></talentbridge-qualification-autocomplete>
            }
        </mat-dialog-content>
    </div>

    <div class="tb-dialog__footer">
        <mat-dialog-actions>
            <div class="tb-dialog__reset-link">
                <a href="javascript:void(0)" (click)="clearDialog()"
                    >Reset Filters</a
                >
            </div>

            <div class="tb-dialog__actions-list">
                <esa-material-button
                    label="Apply"
                    type="secondary"
                    [disabled]="filteredQualificationDtos.length === 0"
                    (click)="closeDialog(false)"
                ></esa-material-button>
            </div>
        </mat-dialog-actions>
    </div>
</div>
