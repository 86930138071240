import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEmployeeService } from 'src/app/services/current-employee.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { EmployeeService } from 'src/app/shared/generated/api/employee.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AsyncPipe, DatePipe } from '@angular/common';
import { EmployeeDto } from 'src/app/shared/generated/model/employee-dto';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider'

@Component({
    selector: 'talentbridge-employee-detail',
    templateUrl: './employee-detail.component.html',
    styleUrls: ['./employee-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
      RouterLinkActive,
      RouterLink,
      RouterOutlet,
      AsyncPipe,
      MatIconModule,
      MatDividerModule,
      DatePipe,
  ],
})
export class EmployeeDetailComponent implements OnInit, OnDestroy {
  public employee$: Observable<EmployeeDto>;
  public currentUser: UserDto;
  public editMode: boolean = false;
  public employeeSideNav: any[] = [];

  editViewButtonClicked: Subscription;
  user: Subscription;

  constructor(
    private employeeService: EmployeeService,
    private currentEmployeeService: CurrentEmployeeService,
    private authenticationService: AuthenticationService,
    private editViewEventService: EditViewEventService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    var userID = parseInt(this.route.snapshot.paramMap.get("id"));

    this.loadData(userID);

    this.route.params.subscribe(res => {
      var newUserID = parseInt(res.id);
      if (newUserID != userID) {
        userID = newUserID;
        this.loadData(newUserID);
      }      
    })

    this.employee$ = this.currentEmployeeService.getCurrentEmployee().pipe(
      map((data) => {
        return data;
      })
    );

    this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });

    this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
      this.editMode = result;
      this.cdr.markForCheck();
    });
  }

  loadData(userID) {    
    this.employeeSideNav = [
      {
        Name: "Summary",
        Link: `/employees/${userID}`,
        CanShow: (emp:EmployeeDto) => true
      },
      {
        Name: "Projects",
        Link: `/employees/${userID}/project-list`,
        CanShow: (emp:EmployeeDto) => true
      },
      {
        Name: "Direct Reports",
        Link: `/employees/${userID}/direct-reports`,
        CanShow: (emp:EmployeeDto) => emp.DirectReportsCount > 0
      },
      {
        Name: "Qualifications",
        Link: `/employees/${userID}/qualifications`,
        CanShow: (emp:EmployeeDto) => true
      },
      {
        Name: "Mentoring",
        Link: `/employees/${userID}/mentoring`,
        CanShow: (emp:EmployeeDto) => this.currentEmployeeService.canEditCurrentEmployee(this.currentUser, emp)
      },
    ];
    
    this.employeeService.employeesUserIDGet(userID).subscribe((data) => {
        this.currentEmployeeService.setCurrentEmployee(data);
      },
      () => {
        this.router.navigate([""]);
      }
    );
  }

  ngOnDestroy(): void {
    this.currentEmployeeService.setCurrentEmployee(null);
    this.user.unsubscribe();
    this.editViewButtonClicked?.unsubscribe();
  }
}
