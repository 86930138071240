import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { ExperienceLevelService } from "src/app/shared/generated/api/experience-level.service";
import { EmployeeQualificationUpsertDto } from "src/app/shared/generated/model/employee-qualification-upsert-dto";
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { FormsModule } from "@angular/forms";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserClaimsDto } from "src/app/shared/generated/model/user-claims-dto";
import { TalentBridgeIconComponent } from "src/app/shared/components/talentbridge-icon/talentbridge-icon.component";

@Component({
    selector: "talentbridge-qualification-add-dialog",
    templateUrl: "qualification-add-dialog.component.html",
    styleUrls: ["./qualification-add-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        EsaMaterialButtonComponent,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonToggleModule,
        FormsModule,
        TalentBridgeIconComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationAddDialog implements OnInit {
    experienceLevels: any[];
    selectedExperienceLevel: number;
    mainAppApiUrl: string;
    currentUser: UserClaimsDto;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<QualificationAddDialog>,
        private experienceLevelService: ExperienceLevelService,
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService
    ) {
        this.mainAppApiUrl = environment.mainAppApiUrl;
        this.selectedExperienceLevel = data.ExperienceLevel?.ExperienceLevelID;
    }
    ngOnInit(): void {        
        if (this.data.Qualification.QualificationType.RequiresExperienceLevel) {
            this.experienceLevelService
                .experienceLevelsGet()
                .subscribe((levels) => {
                    this.experienceLevels = levels;
                    this.experienceLevels = this.experienceLevels.map((level) => {
                        var desc = level.DisplayName.split(", ")[1];
                        level.$Description = desc[0].toUpperCase() + desc.slice(1);
                        return level;
                    });
                    this.cdr.detectChanges();
                });
        }
        this.authenticationService
            .getCurrentUser()
            .subscribe((currentUser) => {
                this.currentUser = currentUser;
                this.cdr.detectChanges();
            });
    }

    closeDialog(cancel: boolean) {
        if (cancel) {
            this.dialogRef.close(null);
            return;
        }

        var dto: EmployeeQualificationUpsertDto = {
            QualificationID: this.data.Qualification.QualificationID,
            ExperienceLevelID: this.selectedExperienceLevel,
        };

        this.dialogRef.close(dto);
    }
}
