
@if (currentUser) {
    <div class="home-view">
        <section class="bento | grid-12">
            <div class="bento__info bento__info-top">
                <div class="info-wrapper">
                    <talentbridge-icon icon="esa_logo_home"></talentbridge-icon>

                    <p>
                        TalentBridge collects all of ESA's talent and experience in
                        one place to better connect our capabilities and streamline
                        our opportunities together.
                    </p>
                </div>
            </div>

            <div class="bento__photo bento__photo-top">
                <img
                    src="../assets/main/home/bento-top.jpg"
                    alt="ESA Employee-Owners on survey"
                />
            </div>

            <div class="bento__info bento__info-bottom">
                <div class="info-wrapper">
                    <p>
                        Find <a routerLink="/employees">people</a> to collaborate
                        with, explore past
                        <a routerLink="/projects">projects</a> and
                        <a routerLink="/clients">clients</a>, and discover our
                        wealth of <a routerLink="/qualifications">skills</a>.
                    </p>
                </div>
            </div>

            <div class="bento__photo bento__photo-bottom">
                <img
                    src="../assets/main/home/bento-bottom.jpg"
                    alt="ESA Employee-Owner at a beach"
                />
            </div>
        </section>

        <section class="get-started">
            <div class="get-started__wrapper | centered-section">
                <h2 class="get-started__header">Get Started</h2>

                <div class="get-started__body">
                    <div class="get-started__feature">
                        <div class="get-started__number"><span>1</span></div>
                        <h3 class="get-started__title">Update your profile</h3>
                        <p class="get-started__copy">
                            Add your biography and contact information.
                            <a
                                routerLink="/employees/{{
                                    this.currentUser?.UserID
                                }}"
                                >Update your profile</a
                            >.
                        </p>
                    </div>

                    <div class="get-started__feature">
                        <div class="get-started__number"><span>2</span></div>
                        <h3 class="get-started__title">
                            Add skills and qualifications
                        </h3>
                        <p class="get-started__copy">
                            Keep your skills list up-to-date.<br />
                            <a
                                routerLink="/employees/{{
                                    this.currentUser?.UserID
                                }}/qualifications"
                                >Add skills</a
                            >.
                        </p>
                    </div>

                    <div class="get-started__feature">
                        <div class="get-started__number"><span>3</span></div>
                        <h3 class="get-started__title">
                            Explore our talent and experience
                        </h3>
                        <p class="get-started__copy">
                            Discover <a routerLink="/employees">people</a> and
                            <a routerLink="/qualifications">skills</a> at ESA, and
                            research past <a routerLink="/projects">projects</a> and
                            <a routerLink="/clients">clients</a>.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="stats">
            <div class="centered-section">
                <h2 class="stats__header">
                    TalentBridge leverages decades of ESA data and information
                </h2>

                <div class="stats__body">
                    <div class="stats__feature">
                        <p class="stats__feature-label">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >badge</mat-icon
                            >
                            <span>Employee-Owners</span>
                        </p>
                        <h3 class="stats__feature-value">{{ metadata?.EmployeeCount | number: '1.0-0' }}</h3>
                    </div>

                    <div class="stats__feature">
                        <p class="stats__feature-label">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >military_tech</mat-icon
                            >
                            <span>Skills & Qualifications</span>
                        </p>
                        <h3 class="stats__feature-value">{{ metadata?.QualificationCount | number: '1.0-0' }}</h3>
                    </div>

                    <div class="stats__feature">
                        <p class="stats__feature-label">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >eco</mat-icon
                            >
                            <span>Projects</span>
                        </p>
                        <h3 class="stats__feature-value">{{ metadata?.ProjectCount | number: '1.0-0' }}</h3>
                    </div>

                    <div class="stats__feature">
                        <p class="stats__feature-label">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >account_balance</mat-icon
                            >
                            <span>Clients</span>
                        </p>
                        <h3 class="stats__feature-value">{{ metadata?.ClientCount | number: '1.0-0' }}</h3>
                    </div>
                </div>
            </div>
        </section>

        <section class="features">
            <div class="features__wrapper | centered-section">
                <h2 class="features__header">What can we do with TalentBridge?</h2>

                <div class="features__body">
                    <div class="feature">
                        <div class="feature__photo">
                            <img
                                src="../assets/main/home/features-1.jpg"
                                alt="ESA Employee-Owners"
                            />
                        </div>

                        <div class="feature__info">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >badge</mat-icon
                            >

                            <h3 class="feature__headline">
                                Keep staff skills and qualifications updated
                            </h3>

                            <div class="feature__copy">
                                <p>
                                    Represent your professional capabilities with
                                    the most up-to-date information to give our
                                    project managers and marketers the fullest view
                                    of our team's experience and credentials.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="feature | even">
                        <div class="feature__photo">
                            <img
                                src="../assets/main/home/features-2.jpg"
                                alt="ESA Employee-Owners"
                            />
                        </div>

                        <div class="feature__info">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >military_tech</mat-icon
                            >

                            <h3 class="feature__headline">
                                Discover Employee-Owners who fill a need in an
                                upcoming opportunity
                            </h3>

                            <div class="feature__copy">
                                <p>
                                    Search our database of skills and experience
                                    that would be the right fit for an upcoming
                                    pursuit. Find talent you didn't know was here at
                                    ESA.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="feature">
                        <div class="feature__photo">
                            <img
                                src="../assets/main/home/features-3.jpg"
                                alt="ESA Employee-Owners"
                            />
                        </div>

                        <div class="feature__info">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >eco</mat-icon
                            >

                            <h3 class="feature__headline">
                                Research related past projects similar to an
                                upcoming pursuit
                            </h3>

                            <div class="feature__copy">
                                <p>
                                    Find successfully won and delivered projects by
                                    ESA that are similar to your opportunity.
                                    Discover who worked on the project, view the
                                    winning proposal, and understand the ingredients
                                    of its success.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="feature | even">
                        <div class="feature__photo">
                            <img
                                src="../assets/main/home/features-4.jpg"
                                alt="ESA Employee-Owners"
                            />
                        </div>

                        <div class="feature__info">
                            <mat-icon
                                style="vertical-align: bottom"
                                fontSet="material-icons-outlined"
                                >account_balance</mat-icon
                            >

                            <h3 class="feature__headline">
                                Explore our history with an existing client
                            </h3>

                            <div class="feature__copy">
                                <p>
                                    Review past projects with our clients to
                                    understand what projects we have worked on and
                                    who at ESA has experience navigating our
                                    relationship with them.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="coming-soon">
            <div class="coming-soon__wrapper | centered-section">
                <div class="coming-soon__photo">
                    <img
                        src="../assets/main/home/coming-soon.jpg"
                        alt="Solar Panels"
                    />
                </div>

                <div class="coming-soon__info">
                    <h4 class="coming-soon__sub-header">Coming Soon</h4>
                    <h3 class="coming-soon__headline">
                        <img
                            src="../assets/main/home/esa-intelligence.png"
                            alt="ESA Intelligence"
                        />
                        ESA Intelligence
                    </h3>

                    <div class="feature__copy">
                        <p>
                            Leverage all of ESA's talent and experience to create
                            winning proposals that identify the best skilled and
                            most diverse project teams - powered by AI.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
}
