<div class="detail-view">
    <div class="page-header has-actions-bar">
        <h1 class="page-title">Mentoring</h1>

        @if (employeeMentoringUpsertDto) {
            <div class="actions-bar">
                @if (canEdit(currentUser)) {
                    <esa-material-button
                        class="secondary-outline-button"
                        icon="edit"
                        label="Edit"
                        (click)="enableEditMode()"
                    >
                    </esa-material-button>
                }
            </div>
        }
    </div>
    <div>
        Our 2025 Mentorship Program is underway now and we have paused collecting interest from employee-owners for this program year. We’ll reopen the form in December for our 2026 pairing process. If you have questions about ESA’s Mentorship Program, please reach out to <a href="mailto:humanresources@esassoc.com">Human Resources</a>.
    </div>
    <!-- <div class="copy copy-2">
        <custom-rich-text
            [customRichTextTypeID]="richTextTypeID"
            [userCanEdit]="canEditText"
            editorId="employeeMentoringPageText">
        </custom-rich-text>
        
    </div>

    @if (employeeMentoringUpsertDto) {
        <form class="form grid-12" #employeeMentoringForm="ngForm">
            <div class="g-col-6">
                <esa-material-ff
                    name="InterestedInMentoring"
                    [editMode]="editMode"
                    [required]="false"
                    label="Are you interested in being a mentor? "
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    (valueChanged)="onChangeInterestedInMentoring($event)"
                    [(ngModel)]="employeeMentoringUpsertDto.InterestedInMentoring">
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="InterestedInBeingMentored"
                    [editMode]="editMode"
                    [required]="false"
                    label="Are you interested in having a mentor?"
                    type="dropdown"
                    [options]="yesNoOptions"
                    optionLabel="label"
                    optionValue="value"
                    (valueChanged)="onChangeInterestedInBeingMentored($event)"
                    [(ngModel)]="employeeMentoringUpsertDto.InterestedInBeingMentored">
                </esa-material-ff>
            </div>
            @if (employeeMentoringUpsertDto.InterestedInMentoring) {
                <div class="g-col-12">
                    <esa-material-ff
                        name="BeenAMentor"
                        [editMode]="editMode"
                        [required]="false"
                        label="Have you previously been a mentor?"
                        type="dropdown"
                        [options]="yesNoOptions"
                        optionLabel="label"
                        optionValue="value"
                        (valueChanged)="onChangeMentor($event)"
                        [(ngModel)]="employeeMentoringUpsertDto.BeenAMentor">
                    </esa-material-ff>
                </div>
                @if (employeeMentoringUpsertDto.BeenAMentor) {
                    <div class="g-col-12">
                        <esa-material-ff
                            name="PreviousMentees"
                            [editMode]="editMode"
                            [required]="false"
                            label="Identify mentees at ESA that you've worked with in the past:"                   
                            type="textarea"
                            [(ngModel)]="employeeMentoringUpsertDto.PreviousMentees">
                        </esa-material-ff>
                    </div>
                    <div class="g-col-12">
                        <esa-material-ff
                            name="PreviousMenteesToMentorAgain"
                            [editMode]="editMode"
                            [required]="false"
                            label="Identify any mentees that you'd like to mentor again:"                   
                            type="textarea"
                            [(ngModel)]="employeeMentoringUpsertDto.PreviousMenteesToMentorAgain">
                        </esa-material-ff>
                    </div>
                }
                <div class="g-col-12">
                    <esa-material-ff
                        name="InterestedInMultipleMentees"
                        [editMode]="editMode"
                        [required]="false"
                        label="Are you able or interested in mentoring more than one staff person?"
                        type="dropdown"
                        [options]="yesNoOptions"
                        optionLabel="label"
                        optionValue="value"
                        [(ngModel)]="employeeMentoringUpsertDto.InterestedInMultipleMentees">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="MentoringSkillTypeIDs"
                        [editMode]="editMode"
                        [required]="false"
                        label="What skills are you most experienced with and/or are your strengths which you can mentor on? (Select all that apply)"
                        type="dropdown"
                        [options]="mentoringSkillTypes"
                        [dropdownMultiple]="true"
                        optionLabel="DisplayName"
                        optionValue="MentoringSkillTypeID"
                        [(ngModel)]="employeeMentoringUpsertDto.MentoringSkillTypeIDs">
                    </esa-material-ff>
                </div>
            }
            @if (employeeMentoringUpsertDto.InterestedInBeingMentored) {
                <div class="g-col-12">
                    <esa-material-ff
                        name="ShortTermGoals"
                        [editMode]="editMode"
                        [required]="false"
                        label="What professional short-term (next 1-5 years) goals have you set for yourself?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.ShortTermGoals">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="LongTermGoals"
                        [editMode]="editMode"
                        [required]="false"
                        label="What professional long-term (5+ years) goals have you set for yourself?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.LongTermGoals">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="SkillsToDevelop"
                        [editMode]="editMode"
                        [required]="false"
                        label="What skills are you looking to grow and develop through the program?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.SkillsToDevelop">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="Strengths"
                        [editMode]="editMode"
                        [required]="false"
                        label="What do you consider to be your strengths (technical and professional)?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.Strengths">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="ChallengingRecentProject"
                        [editMode]="editMode"
                        [required]="false"
                        label="Describe a recent project you worked on that you found particularly challenging or rewarding. What did you learn from this experience?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.ChallengingRecentProject">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="SpecificPreferencesForMentor"
                        [editMode]="editMode"
                        [required]="false"
                        label="Do you have any specific preferences regarding the mentor with whom you are paired? (Example: same or different business group, technical background, location, gender, etc.)"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.SpecificPreferencesForMentor">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="SpecificMentor"
                        [editMode]="editMode"
                        [required]="false"
                        label="Is there a specific mentor with whom you would like to be paired?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.SpecificMentor">
                    </esa-material-ff>
                </div>
            }
            @if (employeeMentoringUpsertDto.InterestedInMentoring || employeeMentoringUpsertDto.InterestedInBeingMentored) {
                <div class="g-col-12">
                    <esa-material-ff
                        name="CommunicationPreferences"
                        [editMode]="editMode"
                        [required]="false"
                        label="How do you prefer to communicate? (e.g., email, phone, video calls, in-person)"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.CommunicationPreferences">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="SpecificAccommodations"
                        [editMode]="editMode"
                        [required]="false"
                        label="Do you require any specific accommodations to support your full engagement in ESA’s mentorship program?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.SpecificAccommodations">
                    </esa-material-ff>
                </div>
                <div class="g-col-12">
                    <esa-material-ff
                        name="OutsideHobbies"
                        [editMode]="editMode"
                        [required]="false"
                        label="What are your hobbies or interests outside of work?"                   
                        type="textarea"
                        [(ngModel)]="employeeMentoringUpsertDto.OutsideHobbies">
                    </esa-material-ff>
                </div>
            }

            @if (editMode) {
                <div class="g-col-12 actions-bar actions-bar__footer">
                    <esa-material-button
                        style="margin-right: 1rem"
                        type="primary"
                        label="Save"
                        icon="save"
                        [tooltip]="
                            !employeeMentoringForm.form.valid
                                ? 'You are missing required fields.'
                                : ''
                        "
                        (click)="saveForm(employeeMentoringForm)"
                        [disabled]="!employeeMentoringForm.form.valid"
                    >
                    </esa-material-button>
                    <esa-material-button
                        type="clear"
                        label="Cancel"
                        icon="cancel"
                        (click)="cancelEditMode()"
                    >
                    </esa-material-button>
                </div>
            }
        </form>
    } -->
</div>
