<div class="tb-dialog">
    <div class="tb-dialog__header">
        <h2 mat-dialog-title>
            <mat-icon>military_tech</mat-icon>
            Add Skill or Qualification
        </h2>

        <div class="tb-dialog__close" (click)="closeDialog(true)">
            <talentbridge-icon icon="dialog_close"></talentbridge-icon>
        </div>
    </div>

    <div class="tb-dialog__body">
        <mat-dialog-content>
            <div class="cta">
                Add the skill <strong>{{ data.Qualification.Name }}</strong> to 
                <img class="user-button__image" src="{{ mainAppApiUrl }}/users/{{ currentUser.UserID }}/photoThumbnail?uid={{ currentUser.UserGuid }}&secure={{ currentUser.FileAccessToken }}" />
                <strong>{{ currentUser?.LoginName }}'s</strong>
                profile.    

            </div>
            @if (this.data.Qualification.QualificationType.RequiresExperienceLevel) {
                <p class="tb-dialog__label">
                    Set your experience level:
                </p>
                <mat-button-toggle-group [(ngModel)]="selectedExperienceLevel" aria-label="Experience Level">
                    @for (expLevel of experienceLevels; track $index) {
                        <mat-button-toggle [value]="expLevel.ExperienceLevelID">
                            <div class="stars">
                                @for (_ of [].constructor(expLevel.ExperienceLevelID); track _) {
                                    <mat-icon [title]="expLevel.DisplayName">star_rate</mat-icon>
                                }
                            </div>
                            <div class="name">{{expLevel.Name}}</div>
                            
                            <span class="description">{{expLevel.$Description}}</span>
                        </mat-button-toggle>

                        @if ($index % 2 !== 0) {
                            <div style="display:block;"></div>
                        }
                    }
                </mat-button-toggle-group>
            }
        </mat-dialog-content>
    </div>

    <div class="tb-dialog__footer">
        <div class="tb-dialog__actions-list">
            <mat-dialog-actions align="end">
                <esa-material-button
                    label="Submit"
                    type="secondary"
                    [disabled]="this.data.Qualification.QualificationType.RequiresExperienceLevel && this.selectedExperienceLevel == null"
                    (click)="closeDialog(false)"
                ></esa-material-button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
