
  <mat-chip-set #chipList aria-label="Tag display">
    @for (qual of qualifications; track qual) {
      <mat-chip >
        <qualification-chips-icon [qualificationTypeID]="qual.Qualification.QualificationType.QualificationTypeID"></qualification-chips-icon>
        <a [routerLink]="['/employees', qual.UserID, 'qualifications']">{{qual.Qualification.Name}}</a>
        @if (qual.ExperienceLevel && qual.ExperienceLevel.ExperienceLevelID) {
            @for (_ of [].constructor(qual.ExperienceLevel.ExperienceLevelID); track _) {
                <mat-icon [title]="qual.ExperienceLevel.DisplayName">star_rate</mat-icon>
            }
        }
        @if (qual.ExperienceLevel && qual.ExperienceLevel.ExperienceLevelID == 0) {
            <mat-icon [title]="qual.ExperienceLevel.DisplayName"
            fontSet="material-icons-outlined">star_rate</mat-icon>
        }
    </mat-chip>
    }
  </mat-chip-set>
