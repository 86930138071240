import { Component } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { RouterLink } from "@angular/router";
import { AgRendererComponent } from "ag-grid-angular";
import { QualificationChipsIconComponent } from "../../qualification-chips-icon/qualification-chips-icon.component";

@Component({
    selector: "qualification-chips-renderer",
    templateUrl: "./qualification-chips-renderer.component.html",
    styleUrls: ["./qualification-chips-renderer.component.scss"],
    standalone: true,
    imports: [
    MatChipsModule,
    MatIconModule,
    RouterLink,
    QualificationChipsIconComponent
]
})
export class QualificationChipsRendererComponent implements AgRendererComponent {
    qualifications: any[];

    agInit(params: any): void {
        if (params.Qualifications) {
            this.qualifications = params.Qualifications;
            this.qualifications.sort((tagA, tagB) => tagA.Qualification.Name.localeCompare(tagB.Qualification.Name));
        } else {
            this.qualifications = [];
        }
    }

    refresh(params: any): boolean {
        return false;
    }
}
