import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatOption, MatSelect } from "@angular/material/select";
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { QualificationAutoCompleteComponent } from "src/app/shared/components/qualification-autocomplete/qualification-autocomplete.component";
import { TalentBridgeIconComponent } from "src/app/shared/components/talentbridge-icon/talentbridge-icon.component";
import { ExperienceLevelService } from "src/app/shared/generated/api/experience-level.service";
import { QualificationService } from "src/app/shared/generated/api/qualification.service";
import { QualificationStatusEnum } from "src/app/shared/generated/enum/qualification-status-enum";
import { EmployeeQualificationSummaryDto } from "src/app/shared/generated/model/employee-qualification-summary-dto";
import { ExperienceLevelDto } from "src/app/shared/generated/model/experience-level-dto";
import { QualificationDto } from "src/app/shared/generated/model/qualification-dto";

@Component({
    selector: "talentbridge-qualification-filter-dialog",
    templateUrl: "qualification-filter-dialog.component.html",
    styleUrls: ["./qualification-filter-dialog.component.scss"],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        EsaMaterialButtonComponent,
        EsaMaterialFormFieldComponent,
        MatFormFieldModule,
        FormsModule,
        MatLabel,
        MatSelect,
        MatOption,
        MatInputModule,
        MatIconModule,
        QualificationAutoCompleteComponent,
        TalentBridgeIconComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationFilterDialog {
    @ViewChild(QualificationAutoCompleteComponent) qualificationAutoCompleteComponent: QualificationAutoCompleteComponent;
    experienceLevels:ExperienceLevelDto[];
    qualifications: QualificationDto[];
    selectedExperienceLevel: ExperienceLevelDto;
    inputQualificationDtos: EmployeeQualificationSummaryDto[] = [];
    filteredQualificationDtos: EmployeeQualificationSummaryDto[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<QualificationFilterDialog>,
        private experienceLevelService: ExperienceLevelService,
        private qualificationService: QualificationService,
        private cdr: ChangeDetectorRef
    ) {
        this.inputQualificationDtos = [...data.FilteredQualificationDtos];
        this.filteredQualificationDtos = [...data.FilteredQualificationDtos];

        this.experienceLevelService.experienceLevelsGet().subscribe(expLevels => {
            this.experienceLevels = [{ExperienceLevelID: null, DisplayName: "Any", Name: "Any"},
            {ExperienceLevelID: -1, DisplayName: "Any (excluding No Experience)", Name: "Any Excluding No Experience"},
             ...expLevels];
            this.cdr.detectChanges();
        });
        this.qualificationService.qualificationsGet().subscribe((qualifications) => {
            this.qualifications = qualifications.filter((q) => {
                return q.QualificationStatus.QualificationStatusID === QualificationStatusEnum.Approved;
            });
            this.cdr.markForCheck();
        });

    }

    closeDialog(cancel: boolean) {
        if (cancel) {
            this.dialogRef.close(null);
            return;
        }

        this.dialogRef.close(this.filteredQualificationDtos);
    }

    qualificationAdded(event: any) {
        this.filteredQualificationDtos.push(event);
        this.cdr.markForCheck();
    }

    qualificationRemoved(event: any) {
        this.filteredQualificationDtos = this.filteredQualificationDtos.filter(i => i !== event);
        this.cdr.markForCheck();
    }

    clearDialog() {
        this.filteredQualificationDtos = [];
        this.qualificationAutoCompleteComponent.clear();
        this.cdr.markForCheck();

        this.dialogRef.close(this.filteredQualificationDtos);
    }

}
