import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { ProjectDto } from '../shared/generated/model/project-dto';
import { ProjectUpsertDto } from '../shared/generated/model/project-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';
import { AdminConfigurationService } from '../shared/generated/api/admin-configuration.service';
import { AdminConfigurationDto } from '../shared/generated/model/admin-configuration-dto';

@Injectable({
  providedIn: 'root'
})
export class CurrentProjectService {
  currentProject$ = new BehaviorSubject<ProjectDto>(null);
  configuration = null;

  constructor(
    private authenticationService: AuthenticationService,
    private configurationService: AdminConfigurationService
  )
  {
      this.configurationService.adminConfigurationGet().subscribe((result) => {
        this.configuration = result;
      });
  }

  setCurrentProject(project: ProjectDto): void {
      this.currentProject$.next(project);
  }

  getCurrentProject(): Observable<any> {
      return this.currentProject$.asObservable();
  }

  createProjectDto(project: ProjectDto) {
      const projectDto = new ProjectUpsertDto({
          Name: project?.Name,
          ClientID: project?.Client?.ClientID,
          DNumber: project?.DNumber,
          Status: project?.Status,
          ProjectManager: project?.ProjectManager,
          ProjectType: project?.ProjectType,
          ServiceType: project?.ServiceType,
          Market: project?.Market,
          StartDate: project?.StartDate ? new Date(project.StartDate) : null,
          EndDate: project?.EndDate ? new Date(project.EndDate) : null,
          Address1: project?.Address1,
          Address2: project?.Address2,
          Address3: project?.Address3,
          City: project?.City,
          State: project?.State,
          ZipCode: project?.ZipCode,
          County: project?.County,
          Country: project?.Country,
          Latitude: project?.Latitude,
          Longitude: project?.Longitude,
          Description: project?.Description,
          StandardDescription: project?.StandardDescription,
          OpportunityDescription: project?.OpportunityDescription
      });
      return projectDto;
  }

  canEditCurrentProject(currentUser: UserDto, project: ProjectDto): boolean {
    if (this.authenticationService.hasPermission(currentUser, PermissionEnum.ProjectRights, RightsEnum.Update)) {
      return true;
    }

    if (!currentUser || !project) {
      return false;
    }

    if (this.configuration.NormalUsersCanEditTheirProjectProperties) {
      return project.ProjectTeamUserIDs.includes(currentUser.UserID);
    }
    else {
      return false;
    }
  }
}
